'use client';

import { useAtomValue } from 'jotai/index';
import {
  useEffect,
  useRef,
} from 'react';
import { useIsMounted } from 'usehooks-ts';

import {
  analyticInit,
  analyticSetUser,
  analyticWatchPath,
} from '@/features/analytic/enteties/analytic';
import { loggedUserAtom } from '@/features/user/atoms/logged-user-atom';
import { usePathname } from '@/i18n/routing';

// Need to be in each page and set new pathname to Google analytic
export default function GoogleAnalyticInit() {
  const isMounted = useIsMounted();
  const loggedUser = useAtomValue(loggedUserAtom);
  const oldPathRef = useRef<string>('');
  const pathname = usePathname();

  useEffect(() => {
    analyticInit();
  }, [isMounted]);

  useEffect(() => {
    if (loggedUser) {
      analyticSetUser(loggedUser.id);
    } else {
      analyticSetUser(null);
    }
  }, [loggedUser]);

  useEffect(() => {
    if (oldPathRef.current !== pathname) {
      oldPathRef.current = pathname;
      analyticWatchPath(pathname);
    }
  }, [loggedUser, pathname]);

  return '';
}
