import {
  deleteCookie,
  getCookie as getCookieClient,
  setCookie,
} from 'cookies-next/client';
import { getCookie as getCookieServer } from 'cookies-next/server';

import { SECONDS } from '@/shared/constants/time-constants';
import {
  type CookieGetData,
  type CookieReturnValue,
} from '@/shared/types/cookies-types';

export const userBearerCookieName = '__roomsterUser__';

interface UserBearerCookie {
  __bearer_token__: string;
}

export function getUserBearerCookie<IsServer extends boolean = false>(cookieOptions?: CookieGetData<IsServer>): CookieReturnValue<IsServer, string> | null {
  if (cookieOptions?.isServer) {
    return (async () => {
      const userBearerCookieValue = await getCookieServer(userBearerCookieName, cookieOptions.options);

      if (userBearerCookieValue) {
        const userBearerCookie = JSON.parse(userBearerCookieValue) as UserBearerCookie;

        // eslint-disable-next-line no-underscore-dangle
        return userBearerCookie.__bearer_token__;
      }

      return null;
    })() as CookieReturnValue<IsServer, string>;
  }

  const userBearerCookieValue = getCookieClient(userBearerCookieName);

  if (userBearerCookieValue) {
    const userBearerCookie = JSON.parse(userBearerCookieValue) as UserBearerCookie;

    // eslint-disable-next-line no-underscore-dangle
    return userBearerCookie.__bearer_token__ as CookieReturnValue<IsServer, string>;
  }

  return null;
}

export function setUserBearerCookie(bearerToken: string, maxAge?: number): void {
  setCookie(userBearerCookieName, JSON.stringify({ __bearer_token__: bearerToken }), { maxAge: maxAge ?? SECONDS.MONTH, path: '/' });
}

export function deleteUserBearerCookie(): true {
  deleteCookie(userBearerCookieName);

  return true;
}
