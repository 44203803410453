import { type Language } from '@/features/language/types/language-types';
import { getUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';
import sendHttpError from '@/libs/logger/requests/send-http-error';

export default async function createCookiesAccept(sessionId: string, locale: Language['locale']) {
  const bearerToken = getUserBearerCookie();

  const headers: HeadersInit = { 'Content-Type': 'application/json' };

  if (bearerToken) {
    headers.Authorization = `Bearer ${bearerToken}`;
  }

  const url = `${process.env.NEXT_PUBLIC_API_URL}/v2/logging/elastic${bearerToken ? '' : '/anon'}`;
  const body = {
    action_name: 'cookies_accepted',
    locale,
    session: sessionId,
  };

  const createCookiesAcceptResponse = await fetch(url, {
    body: JSON.stringify(body),
    cache: 'no-store',
    headers,
    method: 'POST',
  });

  if (!createCookiesAcceptResponse.ok) {
    sendHttpError({
      baseName: 'User cookies accept',
      body,
      functionName: 'createCookiesAccept',
      method: 'POST',
      requestHeaders: headers,
      statusCode: createCookiesAcceptResponse.status,
      url,
    });

    throw new Error('Failed to fetch data');
  }

  return createCookiesAcceptResponse.json();
}
