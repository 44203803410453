import ReactGA from 'react-ga';

import { type User } from '@/features/user/types/user-types';

// If you want to remove something from props set prop value to null, and it will be not send
export function analyticInit() {
  ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID, { debug: process.env.NEXT_PUBLIC_GOOGLE_ANALYTIC_DEBUG === 'true' });
}

export function analyticWatchPath(path: string) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export function analyticSetUser(userId: User['id'] | null) {
  ReactGA.set({ userId });
}

export function analyticEventSignIn() {
  ReactGA.event({
    action: 'Logged in',
    category: 'User',
  });
}

export function analyticEventSentMegaphone() {
  ReactGA.event({
    action: 'Sent Megaphone',
    category: 'User',
  });
}

export function analyticEventSentUserMessage() {
  ReactGA.event({
    action: 'Sent User Profile Message',
    category: 'User',
  });
}

export function analyticEventSentListingMessage() {
  ReactGA.event({
    action: 'Sent Listing Message',
    category: 'User',
  });
}

export function analyticEventCreateFirstListing() {
  ReactGA.event({
    action: 'Created First Listing',
    category: 'User',
  });
}

export function analyticEventCreateAccount() {
  ReactGA.event({
    action: 'Created an Account',
    category: 'User',
  });
}
